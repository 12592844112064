import React, { Component, PureComponent } from 'react';
import {NavLink, Link} from 'react-router-dom'

import './index.scss'

import OurStoryPage from './OurStoryPage'
import Catering from './Catering'
import Banquet from './Banquet'
export {OurStoryPage as ourstory}
export {Catering as catering}
export {Banquet as banquet}

export class SiteNav extends PureComponent {
  render() {
    const pages = [
      {path: "/order", label: "Order"},
      {path: "/menu", label: "Menu"},
    ]
    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external}) => (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        ))}
      </ul>
    </nav>
  )}
}



export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">

          <div className="text">
            <p>Authentic Cuban Cuisine.</p>


            <Link to="/order" className="order-online-link">
              Order Pickup / Delivery
            </Link>
            <p>free delivery + 10% rewards</p>

            <p className="small">
              We have a new, fast delivery partner!
            </p>

            {/* <img src="https://res.cloudinary.com/dgps1radv/image/upload/v1546023510/danstuff/burgerama-hero.png" alt=""/> */}


          </div>


        </div>
      </div>
    );
  }
}
